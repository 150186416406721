import type { Reference } from '@apollo/client'
import { cloneDeep } from 'lodash-es'
import type {
  AttachFieldToTaskTypeParams,
  UnttachFieldFromTaskTypeParams,
  BaseTaskType,
  CreateTaskTypeParams,
  DeleteTaskTypeParams,
  TaskTypeDetail,
} from '#task-type/types'
import { TASK_TYPE_DETAIL_QUERY } from '#task-type/schema'
import { INDEX_STEP } from '#core/constant'

export const useReadTaskTypeFieldsFromCache = (id: string) => {
  const { client } = useApolloClient()
  const cache = client.readQuery<{ taskType?: TaskTypeDetail }>({
    query: TASK_TYPE_DETAIL_QUERY,
    variables: {
      id,
    },
  })
  const taskType = cloneDeep(cache?.taskType)
  taskType?.fieldsWithPosition.sort(sortPos)
  return taskType
}

export const useDeleteFieldFromTaskType = () => {
  const { client } = useApolloClient()
  const { getTaskTypeIdentifier } = useTaskTypeDataConvert()

  const deleteTaskTypeField = async (id: string) => {
    client.cache.modify({
      fields: {
        taskType: (existingTaskType = [], { readField }) => {
          const taskTypeId = readField('id', existingTaskType)
          const fields = readField<Reference[]>('fields', existingTaskType)
          const fieldsWithPosition = readField<Reference[]>(
            'fieldsWithPosition',
            existingTaskType
          )
          let updatedFields: Reference[] = []
          let updatedFieldsWithPosition: Reference[] = []

          if (fields?.length) {
            updatedFields = fields.filter(
              (ref: Reference) => readField('id', ref) !== id
            )
          }

          if (fieldsWithPosition?.length) {
            updatedFieldsWithPosition = fieldsWithPosition.filter(
              (ref: Reference) => {
                const field = readField('field', ref)
                return readField('id', field as Reference) !== id
              }
            )
          }

          client.cache.modify({
            id: getTaskTypeIdentifier(taskTypeId as string),
            fields: {
              fields: () => updatedFields,
              fieldsWithPosition: () => updatedFieldsWithPosition,
            },
          })

          return existingTaskType
        },
      },
    })
  }

  return {
    deleteTaskTypeField,
  }
}

export const useCreateTaskType = () => {
  const { getOptimisticTaskType } = useTaskTypeDataConvert()

  const createTaskType = async (params: CreateTaskTypeParams) => {
    const settingsPackId = params.source === 'settings-pack' ? params.parentId : null
    const optimistic = getOptimisticTaskType(null, { ...params, settingsPackId })
    const { mutate, error, loading } = useAddUpdateTaskTypeMutation({
      variables: {
        input: params,
      },
      optimisticResponse: {
        addUpdateTaskType: {
          taskType: optimistic,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateTaskType?.taskType && params.source === 'board') {
          const { listCache } = useTaskTypeSync()
          listCache.add(params.parentId, data.addUpdateTaskType.taskType)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()

    if (error.value || !data?.data?.addUpdateTaskType) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.addUpdateTaskType }
  }

  return {
    createTaskType,
  }
}

export const useUpdateTaskType = () => {
  const { client } = useApolloClient()
  const { getTaskTypeIdentifier, getOptimisticTaskTypeFields } = useTaskTypeDataConvert()

  const updateTaskType = async (params: Partial<BaseTaskType>) => {
    client.cache.modify({
      id: getTaskTypeIdentifier(params.id as string),
      fields: getOptimisticTaskTypeFields(params),
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateTaskTypeMutation({
      variables: {
        input: params,
      },
    })

    const data = await mutate()

    if (error.value || !data?.data?.addUpdateTaskType) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.addUpdateTaskType }
  }

  return {
    updateTaskType,
  }
}

export const useDeleteTaskType = () => {
  const { client } = useApolloClient()
  const { getTaskTypeIdentifier } = useTaskTypeDataConvert()

  const deleteTaskType = async (params: DeleteTaskTypeParams) => {
    const { mutate, error } = useDeleteTaskTypeMutation(params)

    const data = await mutate()

    if (error.value) {
      return {
        error: error.value,
      }
    }

    client.cache.evict({
      id: getTaskTypeIdentifier(params.id),
    })

    return { data: data?.data?.deleteTaskType }
  }

  return {
    deleteTaskType,
  }
}

export const useTaskTypeFieldMove = () => {
  const { client } = useApolloClient()

  const moveTaskTypeField = async (params: {
    index: number
    id: string
    taskTypeId: string
  }) => {
    const { id, index, taskTypeId } = params
    const { fieldsWithPosition } = useReadTaskTypeFieldsFromCache(taskTypeId)!
    const taskTypeFieldIndex = fieldsWithPosition.findIndex(
      (taskTypeField) => taskTypeField.id === id
    )
    const position = calculateItemPosition(
      index,
      fieldsWithPosition,
      fieldsWithPosition[taskTypeFieldIndex]
    )
    fieldsWithPosition[taskTypeFieldIndex].position = position
    client.cache.modify({
      id: client.cache.identify({
        id: taskTypeId,
        __typename: 'TaskTypeType',
      }),
      fields: {
        fieldsWithPosition: () => fieldsWithPosition,
      },
      optimistic: true,
    })
    const { mutate } = useUpdateTaskTypeFieldPositionMutation({
      taskTypeFieldId: id,
      position,
    })
    await mutate()
  }

  return {
    moveTaskTypeField,
  }
}

export const useAttachFieldToTaskType = async (
  params: Omit<AttachFieldToTaskTypeParams, 'position'>
) => {
  const taskType = useReadTaskTypeFieldsFromCache(params.taskTypeId)

  const position = taskType?.fieldsWithPosition.length
    ? (taskType.fieldsWithPosition.pop()?.position || 0) + 1
    : INDEX_STEP
  const { mutate } = useAttachFieldToTaskTypeMutation(
    {
      ...params,
      position,
    },
    {
      refetchQueries: [
        {
          query: TASK_TYPE_DETAIL_QUERY,
          variables: {
            id: params.taskTypeId,
          },
        },
      ],
    }
  )
  await mutate()
}

export const useUnattachFieldFromTaskType = async (
  params: Omit<UnttachFieldFromTaskTypeParams, 'position'>
) => {
  const { mutate } = useUnattachFieldFromTaskTypeMutation(
    {
      ...params,
    },
    {
      refetchQueries: [
        {
          query: TASK_TYPE_DETAIL_QUERY,
          variables: {
            id: params.taskTypeId,
          },
        },
      ],
    }
  )
  await mutate()
}
