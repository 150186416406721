import { FIELD_FRAGMENT } from '#field/fragment'

export const FIELDS_QUERY = gql`
  ${FIELD_FRAGMENT}
  query FieldsQuery(
    $parentId: ID!
    $source: String!
    $filter: FieldFilterInput
  ) {
    fields(parentId: $parentId, source: $source, filter: $filter) {
      ...Field
    }
  }
`

export const WORKSPACE_FIELDS_QUERY = gql`
  ${FIELD_FRAGMENT}
  query WorkspaceFieldsQuery(
    $workspaceId: ID!
    $filter: FieldFilterInput
  ) {
    workspaceFields(workspaceId: $workspaceId, filter: $filter) {
      ...Field
    }
  }
`

export const FIELD_QUERY = gql`
  ${FIELD_FRAGMENT}
  query FieldQuery($id: ID!) {
    field(id: $id) {
      ...Field
    }
  }
`

export const ADD_UPDATE_FIELD_MUTATION = gql`
  ${FIELD_FRAGMENT}
  mutation AddUpdateFieldMutation($input: AddUpdateFieldInput!) {
    addUpdateField(input: $input) {
      field {
        ...Field
      }
    }
  }
`

export const CREATE_FIELD_MUTATION = gql`
  ${FIELD_FRAGMENT}
  mutation CreateFieldMutation(
    $workspaceId: ID!
    $name: String!
    $type: String!
    $description: String
    $default: String
    $options: JSONString
  ) {
    createField(
      workspaceId: $workspaceId
      name: $name
      type: $type
      description: $description
      default: $default
      options: $options
    ) {
      success
      field {
        ...Field
      }
    }
  }
`

export const UPDATE_FIELD_MUTATION = gql`
  mutation UpdateFieldMutation(
    $id: ID!
    $name: String!
    $description: String
    $default: String
    $options: JSONString
  ) {
    updateField(
      id: $id
      name: $name
      description: $description
      default: $default
      options: $options
    ) {
      success
      field {
        id
      }
    }
  }
`

export const DELETE_FIELD_MUTATION = gql`
  mutation DeleteFieldMutation($id: ID!) {
    deleteField(id: $id) {
      success
    }
  }
`
