import type { Reference } from '@apollo/client'
import type { CreateFieldParams, UpdateFieldParams, Field } from '#field/types'
import { FIELD_FRAGMENT } from '#field/fragment'

export const useFieldOperation = () => {
  const { client } = useApolloClient()
  const { getFieldIdentifier, getOptimisticFieldFields, normalizeParams } = useFieldDataConvert()
  const { getTaskTypeIdentifier } = useTaskTypeDataConvert()

  const createFieldAndAttach = async (
    params: CreateFieldParams,
    taskTypeIds: string[]
  ) => {
    params = normalizeParams(params) as unknown as CreateFieldParams

    const { getOptimisticField } = useFieldDataConvert()
    const { mutate, error } = useAddUpdateFieldMutation({
      variables: {
        input: params,
      },
      optimisticResponse: {
        addUpdateField: {
          field: getOptimisticField(null, params),
        },
      },
      update: (cache, { data }) => {
        if (data?.addUpdateField.field) {
          for (const taskTypeId of taskTypeIds) {
            cache.modify({
              id: getTaskTypeIdentifier(taskTypeId),
              fields: {
                fields: (existingFields = []) => {
                  const newFieldRef = cache.writeFragment({
                    fragment: FIELD_FRAGMENT,
                    data: data.addUpdateField.field,
                  })
                  return [...existingFields, newFieldRef]
                },
              },
            })
          }
        }
      }
    })
    const fieldRes = await mutate()
    if (error.value) {
      return {
        success: false,
        error: error.value.graphQLErrors,
      }
    }

    await Promise.all(taskTypeIds.map((taskTypeId: string) => {
      useAttachFieldToTaskType({
        fieldId: fieldRes?.data?.addUpdateField?.field?.id as string,
        taskTypeId,
      })
    }))

    return {
      success: true,
    }
  }

  const updateField = async (id: string, params: UpdateFieldParams) => {
    const payload = { ...normalizeParams(params), id } as UpdateFieldParams

    client.cache.modify({
      id: getFieldIdentifier(payload.id),
      fields: getOptimisticFieldFields(payload),
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateFieldMutation({
      variables: {
        input: payload,
      },
      update: (_, { data }) => {
        if (data?.addUpdateField.field) {
          const { listCache } = useFieldSync()
          listCache.update(params.id as string, data.addUpdateField.field)
        }
      },
    })
    await mutate()
    if (error.value) {
      return {
        success: false,
        error: error.value.graphQLErrors,
      }
    }

    return {
      success: true,
    }
  }

  const deleteField = async (id: string) => {
    const { deleteTaskTypeField } = useDeleteFieldFromTaskType()
    const { mutate, error } = useDeleteFieldMutation(id, {
      update(_, data) {
        if (data.data?.deleteField.success) {
          deleteTaskTypeField(id)
          client.cache.evict({
            id: getFieldIdentifier(id),
          })
        }
      },
    })
    await mutate()
    if (error.value) {
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  }

  const attachFieldToTaskType = async (field: Field, taskTypeId: string) => {
    const { client } = useApolloClient()
    client.cache.modify({
      id: getTaskTypeIdentifier(taskTypeId),
      fields: {
        fields: (existingFields = []) => {
          const newFieldRef = client.cache.writeFragment({
            fragment: FIELD_FRAGMENT,
            data: field,
          })
          return [...existingFields, newFieldRef]
        },
      },
    })

    return useAttachFieldToTaskType({
      fieldId: field.id,
      taskTypeId,
    })
  }

  const unattachFieldFromTaskType = async (field: Field, taskTypeId: string) => {
    const { client } = useApolloClient()
    client.cache.modify({
      id: getTaskTypeIdentifier(taskTypeId),
      fields: {
        fields: (existingFields = [], { readField }) => {
          client.cache.writeFragment({
            fragment: FIELD_FRAGMENT,
            data: field,
          })
          return existingFields.filter((ref: Reference) => readField('id', ref) !== field.id)
        },
      },
    })

    return useUnattachFieldFromTaskType({
      fieldId: field.id,
      taskTypeId,
    })
  }

  return {
    createFieldAndAttach,
    updateField,
    deleteField,
    attachFieldToTaskType,
    unattachFieldFromTaskType,
  }
}
